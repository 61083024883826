import React from "react";
import AgeOld from "../../assets/ageold.jpeg";
import AcuteCare from "../../assets/acutecare.jpeg";
import CommunityCare from "../../assets/comm_care.jpeg";
import DisabilityCare from "../../assets/disability_care.jpeg";
import { Parallax } from "react-parallax";

const Section3 = () => {
  function MakeCard(param) {
    return (
      <div className="col card border-0 rounded-0 px-0 bg-light">
        <div className="position-relative">
          {/* Parallax */}

          <Parallax
            className="border border-2 border-light p-0"
            bgImage={param.image}
            blur={{ min: -1, max: 1 }}
            strength={-100}
            renderLayer={(percentage) => (
              <div
                className="w-100"
                style={{
                  position: "absolute",
                  background: `rgba(255, 255, 255, .2`,
                  // left: '50%',
                  // top: '50%',
                  width: "100%",
                  height: "50vh",
                }}
              />
            )}
          >
            <div style={{ height: "50vh" }}>
              <div
                className=" position-absolute  start-50 text-center translate-middle-x "
                style={{ width: "100%", top: "60%" }}
              >
                <div className=" text-center card-tite text-white title display-5  text-shadow-black">
                  {param.title}
                </div>
              </div>
            </div>
          </Parallax>
        </div>
        <div className="card-body">
          <div className="card-text  ff1 fs-4 fw300">
            {param.text} &nbsp;
            <a
              href={param.link}
              className="card-link position-absolte botom-0"
              style={{ textDecoration: "none" }}
            >
              read more ...
            </a>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="row row-cols-1 row-cols-md-1 row-cols-xl-2  g-4  p-0">
        <div className="row row-cols-1 row-cols-sm-2 mx-auto  px-0">
          {MakeCard(itemList[0])}
          {MakeCard(itemList[1])}
        </div>
        <div className="row row-cols-1 row-cols-sm-2 px-0 mx-auto">
          {MakeCard(itemList[2])}
          {MakeCard(itemList[3])}
        </div>
        {/* {MakeCard()} */}
        {/* {MakeCard()} */}
        {/* {MakeCard()} */}
      </div>
    </div>
  );
};

const itemList = [
  {
    image: AgeOld,
    title: "Aged Care",
    text: (
      <span>
        As an experienced organization in the healthcare industry, we offer the
        best Aged Care services. For this important service, we offer
        experienced nursing staff who can quickly accept any challenges and
        solve the issues. Aged care service is a very difficult task. So, we
        always prefer the most experienced staff for this job.
      </span>
    ),
    link: "/#",
  },
  {
    image: AcuteCare,
    title: "Acute Care",
    text: (
      <span>
        We offer the best Acute care services in the industry. As a well-being
        service industry, we always offer priority services to our clients.
        Acute care services is one of our specialities. We offer both
        contractual and temporary staff in this sector. Our main aim is to offer
        qualified nurses, to our clients, who can offer acute care services at
        their best.
      </span>
    ),
    link: "/#",
  },
  {
    image: CommunityCare,
    title: "Community Care",
    text: (
      <span>
        As a professional organization, we offer community healthcare services
        to our clients. Community care is one of the special units in health
        care services. This is the kind of care where we extend our help to the
        community services for medical appointments, regular visits, community
        access, shopping, transport and many more.
      </span>
    ),
    link: "/#",
  },
  {
    image: DisabilityCare,
    title: "Disability Care",
    text: (
      <span>
        Disability Care service is something that becomes extremely important
        for people who are deprived of normal life due to illnesses or
        accidents. Disability care is not just providing physical care but also
        psychological care to ensure the complete well-being of the patient. Our
        staff has been trained under these guidelines to provide the best care
        possible.
      </span>
    ),
    link: "/#",
  },
];
export default Section3;
