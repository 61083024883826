import React, { useEffect, useRef, useMemo, useState, useContext } from "react";
import { Link, useNavigate, useLoaderData } from "react-router-dom";
import { address } from "../../api/vars";
import { Parallax } from "react-parallax";
import PicTop from "../../assets/background-01.jpeg";
import TopPanel from "../navbar/toppanel";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import Section6 from "./section6";
import Section7 from "./section7";
import Section8 from "./section8";
import Footer from "../footer/footer";
import BannerText from "./homebannertext";
import AppContext from "../../context/appcontext";
const Main = () => {
  const Context = useContext(AppContext);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const map = useRef(null);
  const Navigate = useNavigate();
  useEffect(() => {
    // Context.ChangeTab(0);
    window.scrollTo(0, 0);
  }, [Context.loginTab]);
  // useEffect(() => {
  //   const node = ref.current;
  //   node.style.opacity = 0.5; // Trigger the animation
  //   console.log(node);
  //   return () => {
  //     node.style.opacity = 0; // Reset to the initial value
  //   };
  // }, [ref]);

  // const insideStyles = {
  //   background: "white",
  //   padding: 20,
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%,-50%)",
  // };
  return (
    <div id="main" className="mx-auto overflow-x-hidden pagefade">
      <Parallax
        bgImage={PicTop}
        blur={{ min: -1, max: 2 }}
        strength={300}
        renderLayer={(percentage) => (
          <div
            style={{
              position: "absolute",
              background: `rgba(0, 0, 0, .3`,
              // left: '50%',
              // top: '50%',
              width: "100vw",
              height: "100%",

              overflow: "hidden",
            }}
          />
        )}
      >
        <div style={{ height: "100vh" }}>
          <TopPanel />
          <BannerText />
          {/* <div style={insideStyles}>Reverse direction</div> */}
        </div>
      </Parallax>

      <section>
        <Section1 />
      </section>
      <section>
        <Section2 />
      </section>

      <section className="  px-3 contaier py-4">
        <div className="py-4 container">
          {/* <hr className="mx-4 py-0 my-0" /> */}

          <div className="fs-4 text-center text-dark-emphasis  col-12 col-sm-10 col-md-8  mx-auto ff1 fw300 user-select-none  ">
            Need Hands is one of the best Medical Support Provider in your area.
            All our support staff are highly skilled, motivated, trained &
            certified so that you get the best support at your place. We are
            just a call away. Book your appointment{" "}
            <a
              href={"tel:" + address.phone}
              className="text-decoration-none fw-bold text-primary"
            >
              now
            </a>{" "}
            to see the difference.
          </div>
          {/* <hr className="w-50 mx-auto " /> */}
        </div>
      </section>
      <section id="ourservices" className="  px-3 bg-light vh-10">
        <Section3 />
      </section>
      <section id="b" className=" pt-4 px- d-none " ref={ref2}>
        <Section4 />
      </section>
      <section className=" pt-4 px-3 bg-light pb-3 ">
        <Section5 />
      </section>
      <section className=" mt-4 px- mb-4">
        <Section6 />
      </section>
      <section className="  px-3 bg-light " id="map">
        <Section7 />
      </section>
      <section className="pt-5  px-3  ">
        <Section8 />
      </section>
      <Footer />
    </div>
  );
};

export default Main;
