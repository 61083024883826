import React, { useContext, useRef, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import "./navclass.css";
import { NavItems } from "./navitems";
import Logo from "../../assets/needhand-logo.png";
import { Offcanvas } from "bootstrap";
import AppContext from "../../context/appcontext";
import { address } from "../../api/vars";
const Navbar = (props) => {
  const Context = useContext(AppContext);
  const closebtn = useRef();
  const [selectedItem, setSelectedItem] = useState(Context.loginTab);
  const itemID = ["Home", "About us", "Services", "Join us", "Contact"];
  var nav;
  return (
    <div className="">
      <nav className="navbar  navbar-expand-md ">
        <div className="container-fluid">
          <div
            className="navbar-toggler  p-0  border-0"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <i className="bi bi-list text-light display-1"></i>
          </div>
          <img src={Logo} width={"180px"} className="d-md-none " alt="" />
          <div
            className="offcanvas offcanvas-start  "
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            // style={{
            //   backgroundImage: "linear-gradient(to bottom, grey,white, white)",
            // }}
          >
            <div className="offcanvas-header bg-dark pacity-75">
              <h5 className="offcanvas-title m-0" id="offcanvasNavbarLabel ">
                <img
                  src={Logo}
                  style={{ width: "60%", height: "auto" }}
                  alt=""
                />
              </h5>
              <a
                href="/#"
                ref={closebtn}
                type="button"
                className="fs-4"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <i className="bi bi-x-square-fill"></i>
              </a>
            </div>
            <div className="offcanvas-body ">
              <ul className="navbar-nav justify-content-start flex-grow-1 ps-md-4 gap-1 gap-md-4  ">
                {NavItems.map((item, idx) => {
                  return (
                    <li className="nav-item " key={idx}>
                      <HashLink
                        to={item.link}
                        className={[
                          "nav-link ",
                          +Context.loginTab === +idx ? " isactive " : " ",
                        ].join(" ")}
                        aria-current="page"
                        // href="/"
                        id="home"
                        onClick={() => {
                          Context.ChangeTab(idx);
                          // setSelectedItem(idx);
                          closebtn.current.click();
                        }}
                      >
                        {item.text}
                      </HashLink>
                      <hr className="my-0 d-block d-md-none" />
                    </li>
                  );
                })}
              </ul>
              <div className=" d-flex flex-column  mt-4 pt-3  d-block d-md-none">
                <Link
                  // data-bs-dismiss="offcanvas"
                  to={"/#map"}
                  className="text-decoration-none text-dark"
                  onClick={() => {
                    closebtn.current.click();
                  }}
                >
                  <div className=" text-center">
                    <i className="bi bi-house fs-1  text-primary"></i>
                  </div>
                  <div>
                    <h6 className="text-center hf1 mb-0 px-5">
                      {address.address}
                    </h6>
                    <h6 className="text-center hf1 text-primary">View Map</h6>
                  </div>
                </Link>
                <div className="  text-center mt-3">
                  <i className="fs-1 me-2 text-primary  bi bi-telephone-fill"></i>
                </div>
                <div className="   text-center hf1">
                  <h6 className=" mb-0"> Call us now</h6>
                  <a
                    href="tel:0478090909"
                    style={{ textDecoration: "none" }}
                    className="text-primary"
                  >
                    {" "}
                    <h5 className="p-0 m-0 hf1">0-478 090 909</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
