import React from "react";
import Nurse1 from "../../assets/nurses1.jpeg";
import MedSupport from "../../assets/meds1.jpeg";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";

const Section1 = () => {
  const ItemList = [
    {
      image: Nurse1,
      title: "Are you a Medical Support Worker?",
      text: (
        <span>
          {" "}
          Are you a Nurse or a Support worker looking for a Job? <br />
          Browse through our list of jobs and submit your CV
        </span>
      ),
      linkText: "Apply Now",
      link: "/jobs",
      action: "Need Job ?",
    },
    {
      image: MedSupport,
      title: "Looking for Medical Support Worker?",
      text: (
        <span>
          Are you a Healthcare Service looking for staff or need Medical Support
          Worker for yourself or for your family/ friend?
        </span>
      ),
      linkText: "Enquire Now",
      link: "/contactus",
      action: "Need Staff ?",
    },
  ];
  const random1 = Math.floor(Math.random() * ItemList.length);
  const random2 = random1 === 1 ? 0 : 1;
  return (
    <div className=" user-select-none">
      <div className="row row-cols-1 row-cols-md-2 p-0 ">
        {MakeCard(ItemList[random1])}
        {MakeCard(ItemList[random2])}
      </div>
    </div>
  );
};

function MakeCard(param) {
  return (
    <Parallax
      className="border border-2 border-light p-0"
      bgImage={param.image}
      blur={{ min: -1, max: 1 }}
      strength={-100}
      renderLayer={(percentage) => (
        <div
          className="w-100"
          style={{
            position: "absolute",
            background: `rgba(0, 0, 0, .4`,
            // left: '50%',
            // top: '50%',
            width: "100%",
            height: "100%",
          }}
        />
      )}
    >
      <div className="vh-100   ">
        <div
          className=" position-absolute  start-50 text-center translate-middle-x "
          style={{ width: "100%", top: "50%" }}
        >
          <div className=" g-info px-4">
            <div className="card-body">
              <div className="card-title title text-white display-5 text-shadow-black">
                {param.action}
              </div>
              <br />
              <p
                className="card-text  text-white px-0 px-sm-4 fs-4 ff1  text-shadow-black "
                style={{ lineHeight: "120%" }}
              >
                {param.text}
              </p>
            </div>
          </div>
        </div>
        <Link
          to={param.link}
          className="btn btn-outline-warning fw-bold mt-3 shadow rounded-0 position-absolute start-50 translate-middle-x "
          style={{ top: "80%" }}
        >
          {param.linkText}
        </Link>
      </div>
    </Parallax>
  );
}

export default Section1;
