import React, { useRef } from "react";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import "../home/home.css";
import Logo from "../../assets/needhand-logo.png";
import NavBar from "./navbar";
import { address } from "../../api/vars";
const TopPanel = (props) => {
  const Navigate = useNavigate();
  return (
    <div>
      <div
        className="py-1 border-bottom border-secondary align-middle d-none d-md-block d-flex "
        // style={{ height: "80px" }}
      >
        <div
          className="bg-inf d-flex justify-content-between my-auto py-2 mx-3"
          //   style={{ height: "100%" }}
        >
          <div className="my-auto">
            <img
              src={Logo}
              width={"190px"}
              height={"60px"}
              className="my-auto "
              alt=""
            />
          </div>
          <div className="fw-light df1 my-auto  d-flex">
            {" "}
            <h5 className="fw-light df1 my-auto h4  d-flex">
              <i className="bi bi-facebook  me-3 text-info"></i>
              <i className="bi bi-twitter  me-3  text-primary"></i>
              <i className="bi bi-linkedin  me-3  text-info"></i>
            </h5>
          </div>
          <div className="text-white my-auto h5 bg-ino d-flex">
            <div className="me-5  d-flex">
              <div className="  my-auto">
                <i className="bi bi-house h1 me-2 text-warning"></i>
              </div>
              <div>
                <HashLink
                  to="/#map"
                  className="text-decoration-none text-white"
                >
                  <h4 className="m-0 p-0 hf1">Our location</h4>
                  <h5 className="p-0 m-0 hf1">View on Map</h5>
                </HashLink>
              </div>
            </div>
            <div className="  d-flex">
              <div className="  my-auto">
                <i className="h1 me-2 text-warning  bi bi-telephone-fill"></i>
              </div>
              <div>
                <h4 className="m-0 p-0 hf1 ">Call us now</h4>
                <a
                  href="tel:0478090909"
                  style={{ textDecoration: "none" }}
                  className="text-primary"
                >
                  {" "}
                  <h5 className="p-0 m-0 hf1">{address.phone}</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavBar />
      {props.children}
    </div>
  );
};

export default TopPanel;
