import React from "react";
import { address } from "../../api/vars";

const ContactBannertext = () => {
  return (
    <div
      className="user-select-none d-flex align-items-cener flex-column  text-white position-absolute  start-0 ps-5"
      style={{ width: "80%", top: "45%", height: "50vh" }}
    >
      <div className="">
        {/* <div className=" position-relative   mt-0 pt-0 "> */}
        <div className="display-3 fw-bold text-white  df1 text-shadow-black d-none d-sm-block">
          Let us <span className="text-warning">Get in Touch</span>
        </div>
        <div className="display-5 fw-bold text-white  df1 text-shadow-black d-block d-sm-none ">
          Let us <span className="text-warning">Get in Touch</span>
        </div>

        {/* </div> */}
      </div>
      <div
        className="text-white mt-1   text-shadow-blac text-spacing-1 "
        // style={{ width: "60w" }}
      >
        <span className=" ff1 fs-3 text-shadow-grey  fw30">
          We are looking forward to hear from you.
          {/* <br className="d-block d-sm-none" /> */}
          {/* <b className="text-shadow-whit"> NeedHands Nursing Agency</b> */}
        </span>
      </div>
    </div>
  );
};

export default ContactBannertext;
