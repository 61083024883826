import React from "react";
import { Parallax } from "react-parallax";
import PicBottom from "../../assets/background-footer-home.jpeg";
import Logo from "../../assets/needhand-logo.png";
import { Link } from "react-router-dom";
import { NavItems } from "../navbar/navitems";
import { address } from "../../api/vars";
import { HashLink } from "react-router-hash-link";
import TMG from "../../assets/tmg.png";
const Footer = () => {
  const Itemlist = [
    {
      id: "#footeraboutus",
      title: "Locate us",
      content: "",
      // content:
      //   "Need hands is the pioneer nurse-providing agency in Australia. We are a leading name in the personal health care industry. As we are experienced in this esteemed industry for quite a longtime, we understand exactly what services people want from us. We are specialized in Aged care, Acute care, and Community care.",

      links: [
        {
          id: "footermaplink",
          icon: "bi-geo-alt-fill",
          text: address.address,
          link: "/#map",
          linkType: "hash",
        },
        {
          icon: " bi-telephone-fill ",
          text: address.phone,
          link: "tel:" + address.phone,
          linkType: "hash",
        },
        {
          icon: "bi-envelope-fill",
          text: "info@needhands.com.au",
          link: "mailto:info@needhands.com.au",
          linkType: "hash",
        },
      ],
    },
    {
      id: "footersitemap",
      title: "Site map",
      content: "",

      links: NavItems,
    },
    {
      id: "footerservices",
      title: "Our Services",
      content: "",
      linktype: "no-hash",

      links: [
        {
          icon: "",
          text: "Aged care",
          link: "#footerservices",
          linktype: "no-hash",
        },
        {
          icon: "",
          text: "Acute care",
          link: "#footerservices",
          linktype: "no-hash",
        },
        {
          icon: "",
          text: "Disabled care",
          link: "#footerservices",
          linktype: "no-hash",
        },
        {
          icon: "",
          text: "Community care",
          link: "#footerservices",
          linktype: "no-hash",
        },
      ],
    },
    {
      id: "footersiteinfo",
      title: "",
      content:
        "Need hands is the pioneer nurse-providing agency in Australia. We are a leading name in the personal health care industry. As we are experienced in this esteemed industry for quite a longtime, we understand exactly what services people want from us. We are specialized in Aged care, Acute care, and Community care.",

      links: [],
    },
  ];
  return (
    <div>
      <Parallax
        bgImage={PicBottom}
        blur={{ min: 12, max: -12 }}
        strength={-100}
        renderLayer={(percentage) => (
          <div
            style={{
              position: "absolute",
              background: `rgba(0, 0, 0, .3`,

              width: "100%",
              height: "100%",
            }}
          />
        )}
      >
        <div style={{ height: "100%" }} className="py-4 px-3 ">
          {Row1()}

          <div className="d-flex row row-cols-sm-2   px-3 ">
            <div className="co">
              <div className="text-white mt-2">{MakeCard(Itemlist[0])}</div>
              <div className="text-white mt-2">{MakeCard(Itemlist[1])}</div>
              <div className="text-white  mt-2">{MakeCard(Itemlist[2])}</div>
            </div>
            <div className="co"> {MakeCard(Itemlist[3])}</div>
            {/* {MakeCard(Itemlist[0])} */}
            {/* {MakeCard(Itemlist[1])} */}
            {/* {MakeCard(Itemlist[2])} */}
          </div>
        </div>
        <div className="text-light pb-3 row row-cols-sm-2  small mx-2 ">
          {/* <div className="text-center text-warning mt-auto">
            Copyright &#169; 2023 NeedHands. All Rights Reserved
          </div> */}

          <div className="mx-auto  col-12 col-sm-8 mt-auto">
            <div className=" text-warning ">
              <div className="d-flex justify-content-center ">
                <div className="mt-auto small">
                  Copyright &#169; 2023 NeedHands. All Rights Reserved
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto  col-12 col-sm-4 ">
            <div className=" text-warning my-auto ">
              <div className="d-flex justify-content-center small">
                <div className="mt-auto ">Powered by</div>
                <img src={TMG} width={40} className="mx-1" />
                <div className="mt-auto">the Media Gear &#169;</div>
              </div>
            </div>
          </div>

          {/* <div className="text-center text-warning my-auto ">
            <div className="d-flex justify-content-end">
              <div className="mt-auto ">Powered by</div>
              <img src={TMG} width={50} className="mx-1" />
              <div className="mt-auto">the Media Gear &#169;</div>
            </div>

          </div> */}
        </div>
      </Parallax>
    </div>
  );
};

function Row1() {
  return (
    <div className="d-flex justify-content-between">
      <div className="">
        <img
          src={Logo}
          className="img-fluid"
          style={{ width: "35vw" }}
          alt=".."
        />

        <div className="d-block d-sm-none my-auto border-top border-secondary border-1 pt-2  ">
          <h5 className="fw-light df2 mt-auto  d-flex justify-content-center">
            <i className="bi bi-facebook  me-3 text-primary"></i>
            <i className="bi bi-twitter  me-3  text-primary"></i>
            <i className="bi bi-linkedin  me-3  text-primary"></i>
          </h5>
        </div>
      </div>
      <div className=" my-auto">
        <div className="d-flex  justify-content-end mt-auto">
          <div>
            <i className="bi bi-telephone-fill display-6 text-warning"></i>
          </div>
          <div className="ms-3 my-auto text-white">
            <div className=" fw-ligh fs-5 ff1  mt-auto">we are 24X7</div>
            <div className="fw-bold fs-4 ff1  mt-auto">{address.phone}</div>
          </div>
        </div>
      </div>{" "}
      <div className="d-none d-sm-block my-auto ">
        <div className="ms-3 my-auto text-white">
          <div className="fs-5 fw-light ff1  mt-auto">follow us</div>
          <h5 className="fw-light df1 mt-auto">
            <i className="bi bi-facebook h3 me-3 text-primary"></i>
            <i className="bi bi-twitter h3 me-3  text-primary"></i>
            <i className="bi bi-linkedin h3 me-3  text-primary"></i>
          </h5>
        </div>
      </div>
    </div>
  );
}
function MakeCard(param) {
  return (
    <footer id={param.id} style={{ height: "100%" }}>
      <div className="car">
        <div className="card-body ">
          <h5 className="card-title text-white ff1 fw-bold h4 mb-0 text-shadow-black  ">
            {param.title}
          </h5>
          {param.content ? (
            <p className="card-text text-white ff1 fs-4 text-shadow-black ">
              {param.content}
            </p>
          ) : (
            ""
          )}
          <ul className=" list-group-flush  ps-0 pt-0 ">
            {param.links.map((el, idx) => {
              return (
                <li className="list-group-item my-1" id={el.id} key={idx}>
                  {el.link.linktype}
                  {el.linkType === "hash" ? (
                    <HashLink to={el.link} style={{ textDecoration: "none" }}>
                      <div className="d-flex">
                        <div className="my-auto me-3">
                          <i
                            className={[" bi  h3 text-white ", el.icon].join(
                              " "
                            )}
                          ></i>
                        </div>
                        <div className="col my-auto text-warning ff1 fs-5 fw-light">
                          <div>{el.text}</div>
                        </div>
                      </div>
                    </HashLink>
                  ) : (
                    <Link to={el.link} style={{ textDecoration: "none" }}>
                      <div className="d-flex">
                        <div className="my-auto me-3">
                          <i
                            className={[" bi  h3 text-white ", el.icon].join(
                              " "
                            )}
                          ></i>
                        </div>
                        <div className="col my-auto text-warning ff1 fs-5 fw-light">
                          <div>{el.text}</div>
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
