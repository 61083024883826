import React, { useContext, useEffect } from "react";
import { address } from "../../api/vars";
import { Parallax } from "react-parallax";
import PicTop from "../../assets/contactus/banner.jpeg";
import TopPanel from "../navbar/toppanel";
import AppContext from "../../context/appcontext";
import ContactBannerText from "./contactbannertext";
import Section1 from "./section1";
import Footer from "../footer/footer";
const ContactForm = () => {
  const Context = useContext(AppContext);
  useEffect(() => {
    Context.ChangeTab(4);
    window.scrollTo(0, 0);
  }, [Context.loginTab]);
  return (
    <div className="pagefade">
      {" "}
      <Parallax
        bgImage={PicTop}
        blur={0}
        strength={300}
        renderLayer={(percentage) => (
          <div
            style={{
              position: "absolute",
              background: `rgba(0, 0, 0, .3`,
              // left: '50%',
              // top: '50%',
              width: "100vw",
              height: "100%",

              overflow: "hidden",
            }}
          />
        )}
      >
        <div style={{ height: "75vh" }}>
          <TopPanel />
          <ContactBannerText />
          {/* <div style={insideStyles}>Reverse direction</div> */}
        </div>
      </Parallax>
      <Section1 />
      <section className="mt-4">
        <Footer />
      </section>
    </div>
  );
};

export default ContactForm;
