import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { address } from "../../api/vars";
const Section1 = () => {
  const Navigate = useNavigate();
  const [contactData, setContactData] = useState({
    fname: "",
    lname: "",
    email: "",
    message: "",
  });

  const itemList = [
    {
      icon: "bi-telephone-fill",
      title: address.phone,
      text: "24/7 Emergency Phone, Call us Anytime",
      link: "/#",
    },
    {
      icon: "bi-geo-alt-fill",
      title: "Address",
      text: address.address,
      link: "/#",
    },
    {
      icon: "bi-envelope-fill",
      title: "Email us",
      text: "info@needhands.com.au",
      link: "/#",
    },
    {
      icon: "bi-geo-alt-fill",
      title: "Address",
      text: address.address,
      link: "/#",
    },
  ];

  return (
    <div className="user-select-none container mt-3">
      <div className="row  ">
        <div className="col-12 col-sm-6  pt- ">
          <div
            className="row row-cols-1  ff1 fs-4 d-flex align-items-start flex-column pb-3"
            style={{ height: "100%" }}
          >
            <div className="title fs-4  mt-2 ">Send us a message</div>
            <p
              className="text-primary-emphasis pt- pe-4 mt-3"
              style={{ textAlign: "justify", textJustify: "inter-word" }}
            >
              You can fill in this contact form, write the requirements and
              details of your enquiry and you will get an answer from our
              professionals within 24 hours and get a free consultation. If you
              have any additional questions about our work, we will be happy to
              send you our answers. We welcome all our clients in need of any
              information from our experts.
            </p>

            <div className="d-sm-block d-none mt-auto">
              <div className="row row-cols-1 row-cols-sm-3 ">
                {MakeCard(itemList[0])}
                {MakeCard(itemList[1])}
                {MakeCard(itemList[2])}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6  p-3 bg-light" id="contactus">
          <div className="ff1 fw-ligt text-dark-emphasis fs-5 ">
            <b>
              We would love to hear from you, please send your message, query or
              feedback and we will get back to you as soon as we can.
            </b>
          </div>
          <fieldset>
            <div>
              <form
                className="row g-3 needs-validation border mt-3 pb-3 pe-3 ff1 fw-bold "
                onSubmit={(e) => {
                  e.preventDefault();

                  const requestOptions = {
                    method: "post",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      to: address.webcontact,
                      from: contactData.email,
                      subject: contactData.fname + " sent an email enquiry ",
                      message: contactData.message,
                    }),
                  };

                  fetch(
                    "https://wp1.marjara.net/mail/mailapi.php",
                    requestOptions
                  ).then((res) => {
                    window.alert(
                      "Thank you for your email, we will get back to you shortly."
                    );
                    setContactData({
                      fname: "",
                      lname: "",
                      email: "",
                      message: "",
                    });
                    window.scrollTo(0, 0);
                  });
                }}
              >
                <div className="col">
                  <input
                    type="text"
                    className="form-control ff1"
                    id="firstname"
                    value={contactData.fname}
                    placeholder="your first name"
                    required
                    onChange={(val) => {
                      setContactData({
                        ...contactData,
                        fname: val.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control ff1"
                    id="lastname"
                    value={contactData.lname}
                    placeholder="your last name"
                    required
                    onChange={(val) => {
                      setContactData({
                        ...contactData,
                        lname: val.target.value,
                      });
                    }}
                  />
                </div>

                <div className="">
                  <input
                    type="email"
                    className="form-control ff1"
                    id="email"
                    value={contactData.email}
                    placeholder="your email id"
                    required
                    onChange={(val) => {
                      setContactData({
                        ...contactData,
                        email: val.target.value,
                      });
                    }}
                  />
                </div>
                <div className="">
                  <textarea
                    className="form-control ff1"
                    placeholder="write your message here"
                    required
                    style={{ height: "175px" }}
                    value={contactData.message}
                    onChange={(val) => {
                      setContactData({
                        ...contactData,
                        message: val.target.value,
                      });
                    }}
                  />
                </div>

                <div className="col-12">
                  <button
                    className="mt-2 btn btn-warning btn-sm ff1"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </fieldset>
        </div>
      </div>
      <div className="d-block d-sm-none">
        <div className="row row-cols-1 row-cols-md-3 ">
          {MakeCard(itemList[0])}
          {MakeCard(itemList[1])}
          {MakeCard(itemList[2])}
          {/* {MakeCard(itemList[3])} */}
        </div>
      </div>
    </div>
  );
};

export function MakeCard(param) {
  return (
    <div className="card border-0  px-  mb-3">
      <div className="card-body px-1  p-0">
        <div className="card-title  mb-0">
          <i className={`bi ${param.icon} fs-3 text-primary`}></i>
        </div>
        <p className="card-title fw-bold fs-4 ff1 mb-0  ">{param.title}</p>
        <p className="card-text small ff1 fs-5">{param.text}</p>
      </div>
    </div>
  );
}

export default Section1;
