import React, { useState } from "react";
import ContactForm from "./section8contactform";
import { useNavigate } from "react-router-dom";
import { address } from "../../api/vars";
const Section8 = () => {
  const Navigate = useNavigate();
  const [contactData, setContactData] = useState({
    fname: "",
    lname: "",
    email: "",
    message: "",
  });

  const itemList = [
    {
      icon: "bi-telephone-fill",
      title: address.phone,
      text: "24/7 Emergency Phone, Call us Anytime",
      link: "/#",
    },
    {
      icon: "bi-geo-alt-fill",
      title: "Address",
      text: address.address,
      link: "/#",
    },
    {
      icon: "bi-envelope-fill",
      title: "Email us",
      text: "info@needhands.com.au",
      link: "/#",
    },
    {
      icon: "bi-geo-alt-fill",
      title: "Address",
      text: address.address,
      link: "/#",
    },
  ];

  return (
    <div className="user-select-none">
      <div className="row  ">
        <div className="col-12 col-lg-7  pt- ">
          <div className="display-5 title  mb-4 df1">
            Let’s get{" "}
            <span className="text-warning text-shadow-black">together</span>
          </div>
          <p
            className="text-dark-emphasis  ff1 fs-4"
            style={{ textAlign: "justify", textJustify: "inter-word" }}
          >
            Nursing is the noblest of the professions. When someone requires
            care during illness or old age, it is of utmost importance that the
            caregiver is passionate and truly caring in nature. When we choose
            our staff, the very first quality we ensure in them (obviously after
            the education and experience facts) is how passionate they are
            towards the profession. Needhands takes pride in providing the best
            nursing staff in each sector and is also honoured to have the best
            reviews from existing clients.
          </p>
          <div className="row row-cols-1  ff1 fs-4">
            <p
              className="text-primary-emphasis"
              style={{ textAlign: "justify", textJustify: "inter-word" }}
            >
              You can fill in this contact form, write the requirements and
              details of your enquiry and you will get an answer from our
              professionals within 24 hours and get a free consultation. If you
              have any additional questions about our work, we will be happy to
              send you our answers. We welcome all our clients in need of any
              information from our experts.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-3 ">
            {MakeCard(itemList[0])}
            {MakeCard(itemList[1])}
            {MakeCard(itemList[2])}
            {/* {MakeCard(itemList[3])} */}
          </div>
        </div>
        <div className="col-12 col-lg-5  p-3 bg-light " id="contactus">
          <div className="title fs-4 mb-2 ">Send us a message</div>
          <div className="ff1 fw-light fs-5">
            We would love to hear from you, please send your message, query or
            feedback and we will get back to you as soon as we can.
          </div>
          <fieldset
            disabled
            onClick={() => {
              Navigate("/contactus");
              console.log("clicked");
            }}
          >
            <div>
              <form
                className="row g-3 needs-validation border mt-3 pb-3 pe-3 ff1 fw-bold "
                onSubmit={(e) => {
                  e.preventDefault();
                  const payLoad = {
                    // method: "POST",
                    from: "HHA admin<do-not-reply@psinigeria.org>",
                    // from: "admin<do-not-reply@psinigeria.org>",

                    // from: props.from,
                    to: "pritpal@psinigeria.org",
                    subject: "test subject",
                    body: "this is test email",
                  };
                  const requestOptions = {
                    method: "post",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      to: address.webcontact,
                      from: contactData.email,
                      subject: contactData.fname + " sent an email enquiry ",
                      message: contactData.message,
                    }),
                  };

                  console.log(JSON.stringify(payLoad));

                  fetch(
                    "https://wp1.marjara.net/mail/mailapi.php",
                    requestOptions
                  ).then((res) => {
                    window.alert(
                      "Thank you for your email, we will get back to you shortly."
                    );
                    setContactData({
                      fname: "",
                      lname: "",
                      email: "",
                      message: "",
                    });
                    window.scrollTo(0, 0);
                  });
                }}
              >
                <div className="col">
                  <input
                    type="text"
                    className="form-control ff1"
                    id="firstname"
                    value={contactData.fname}
                    placeholder="your first name"
                    required
                    onChange={(val) => {
                      setContactData({
                        ...contactData,
                        fname: val.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control ff1"
                    id="lastname"
                    value={contactData.lname}
                    placeholder="your last name"
                    required
                    onChange={(val) => {
                      setContactData({
                        ...contactData,
                        lname: val.target.value,
                      });
                    }}
                  />
                </div>

                <div className="">
                  <input
                    type="email"
                    className="form-control ff1"
                    id="email"
                    value={contactData.email}
                    placeholder="your email id"
                    required
                    onChange={(val) => {
                      setContactData({
                        ...contactData,
                        email: val.target.value,
                      });
                    }}
                  />
                </div>
                <div className="">
                  <textarea
                    className="form-control ff1"
                    placeholder="write your message here"
                    required
                    style={{ height: "175px" }}
                    value={contactData.message}
                    onChange={(val) => {
                      setContactData({
                        ...contactData,
                        message: val.target.value,
                      });
                    }}
                  />
                </div>

                <div className="col-12">
                  <button
                    className="mt-2 btn btn-warning btn-sm ff1"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export function MakeCard(param) {
  return (
    <div className="card border-0  px-  mb-3">
      <div className="card-body px-1  p-0">
        <div className="card-title  mb-0">
          <i className={`bi ${param.icon} fs-3 text-primary`}></i>
        </div>
        <p className="card-title fw-bold fs-4 ff1 mb-0  ">{param.title}</p>
        <p className="card-text small ff1 fs-5">{param.text}</p>
      </div>
    </div>
  );
}

export default Section8;
