import React, { ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { address } from "../../api/vars";
import AppContext from "../../context/appcontext";
import { MailAxios, Mailer } from "../../api/mailer";
const Section1 = () => {
  const Context = useContext(AppContext);
  const Navigate = useNavigate();
  const [contactData, setContactData] = useState({
    fname: "",
    lname: "",
    email: "",
    message: "",
    file: "",
  });

  const [file, setFile] = useState();

  const itemList = [
    {
      icon: "bi-telephone-fill",
      title: address.phone,
      text: "24/7 Emergency Phone, Call us Anytime",
      link: "/#",
    },
    {
      icon: "bi-geo-alt-fill",
      title: "Address",
      text: address.address,
      link: "/#",
    },
    {
      icon: "bi-envelope-fill",
      title: "Email us",
      text: "info@needhands.com.au",
      link: "/#",
    },
    {
      icon: "bi-geo-alt-fill",
      title: "Address",
      text: address.address,
      link: "/#",
    },
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      console.log(e.target);
      //   setContactData({ ...contactData, file: e.target.files[0] });
    }
  };

  return (
    <div className="user-select-none container mt-3">
      <div className="row  ">
        <div className="col-12   p-3 bg-light">
          <div className="ff1 fw-ligt text-dark-emphasis fs-5 ">
            <b>
              We at NeedHands welcome skilled, experience, and passionate staff
              to join as a tam member. Since, we reeive number of applications,
              at times it may be difficult to respond to each job application.
              Depending on your suitability and current openings, we shall
              revert to you, as appropriate. Thank you for our patience and
              understanding.
            </b>
          </div>
          <fieldset>
            <div>
              <form
                className="row g-3 needs-validation border mt-3 pb-3 pe-3 ff1 fw-bold "
                onSubmit={(e) => {
                  e.preventDefault();

                  //   const requestOptions = {
                  // mode: "no-cors",
                  // method: "POST",
                  // headers: { "Content-Type": "application/json" },
                  // attachment: contactData.file,

                  // body: JSON.stringify({
                  //   to: " pritpal@marjara.net",
                  //   from: contactData.email,
                  //   subject: contactData.fname + " sent an email enquiry ",
                  //   message: contactData.message,
                  //   attachment: file,
                  //   //   attachment: contactData.file,
                  // }),
                  // to: "abcd",
                  // body: JSON.stringify(file),
                  //   };

                  const data = new FormData();
                  data.append(
                    "data",
                    JSON.stringify({
                      to: address.jobs,
                      from: contactData.email,
                      sender: contactData.fname,
                      replyto: contactData.email,
                      subject: "New job application",
                      message: contactData.message,
                    })
                  ); //append the values with key, value pair

                  data.append("file", file[0]);

                  //   console.log(data.get("file"));
                  //   console.log(data.get("name"));

                  MailAxios.post(Mailer, data).then((res) => {
                    window.alert(
                      "Your application has been submitted. Thank you for your interest in job at NeedHands."
                    );
                    console.log(res.data);
                    setContactData({
                      fname: "",
                      lname: "",
                      email: "",
                      message: "",
                      file: "",
                    });
                    Navigate("/");
                    Context.ChangeTab(0);
                  });
                }}
              >
                <div className="col">
                  <input
                    type="text"
                    className="form-control ff1"
                    id="firstname"
                    value={contactData.fname}
                    placeholder="your first name"
                    required
                    onChange={(val) => {
                      setContactData({
                        ...contactData,
                        fname: val.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control ff1"
                    id="lastname"
                    value={contactData.lname}
                    placeholder="your last name"
                    required
                    onChange={(val) => {
                      setContactData({
                        ...contactData,
                        lname: val.target.value,
                      });
                    }}
                  />
                </div>
                <div className="">
                  <input
                    type="email"
                    className="form-control ff1"
                    id="email"
                    value={contactData.email}
                    placeholder="your email id"
                    required
                    onChange={(val) => {
                      setContactData({
                        ...contactData,
                        email: val.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <label className="form-label">Upload your CV</label> */}
                  <label className="form-label ps-2">Upload your CV</label>
                  {/* <label for="upload">Click me to upload image</label> */}
                  <input
                    id="upload"
                    className="form-control "
                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    //   name={contactData.email + "CV"}
                    required
                    type="file"
                    name="attachment"
                    placeholder="Attachment"
                    //   value={contactData.file}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      console.log(e.target.files);
                      setFile(e.target.files);
                      // setFile(e..target.files[0])
                      // handleFileChange(e);
                      // console.log(e.target);
                      setContactData({
                        ...contactData,
                        file: e.target.files[0],
                      });
                    }}
                  />
                </div>
                <div className="">
                  <textarea
                    className="form-control ff1"
                    placeholder="write briefly about yourself and your experience"
                    required
                    style={{ height: "175px" }}
                    value={contactData.message}
                    onChange={(val) => {
                      setContactData({
                        ...contactData,
                        message: val.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-12">
                  <button
                    className="mt-2 btn btn-warning btn-sm ff1"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <button
                hidden
                onClick={() => {
                  const tmp = new FormData();
                  //   tmp.append("file");
                  //   tmp.append("abc", JSON.stringify(obj));
                  console.log(tmp);
                }}
              >
                test
              </button>
            </div>
          </fieldset>
        </div>
      </div>
      <div className="d-block d-sm-none">
        <div className="row row-cols-1 row-cols-md-3 ">
          {MakeCard(itemList[0])}
          {MakeCard(itemList[1])}
          {MakeCard(itemList[2])}
          {/* {MakeCard(itemList[3])} */}
        </div>
      </div>
    </div>
  );
};

export function MakeCard(param) {
  return (
    <div className="card border-0  px-  mb-3">
      <div className="card-body px-1  p-0">
        <div className="card-title  mb-0">
          <i className={`bi ${param.icon} fs-3 text-primary`}></i>
        </div>
        <p className="card-title fw-bold fs-4 ff1 mb-0  ">{param.title}</p>
        <p className="card-text small ff1 fs-5">{param.text}</p>
      </div>
    </div>
  );
}

export default Section1;
