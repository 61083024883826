import React from "react";
import { address } from "../../api/vars";

const Homebannertext = () => {
  return (
    <div
      className="user-select-none d-flex align-items-center flex-column  text-white position-absolute  start-50 text-center translate-middle-x"
      style={{ width: "80%", top: "45%", height: "50vh" }}
    >
      <div className="">
        {/* <div className=" position-relative   mt-0 pt-0 "> */}
        <div className="display-3 fw-bold text-white  df1 text-shadow-black d-none d-sm-block">
          Making a <span className="text-warning">Difference</span>
        </div>
        <div className="display-5 fw-bold text-white  df1 text-shadow-black d-block d-sm-none mb-1">
          Making a <span className="text-warning">Difference </span>
        </div>
        <div
          className="position-absolte   text-end hf1  w-100  "
          // style={{ top: -20 }}
        >
          <div className=" animate__animated animate__fadeInDown animate__delay-1s text-shadow-black fs-4  fw-old text-white fst-italic">
            For the Nurses, By the Nurses
          </div>
        </div>
        {/* </div> */}
      </div>
      <div
        className="text-white mt-4    text-shadow-blac text-spacing-1"
        // style={{ width: "60w" }}
      >
        {/* <span className="d-block d-sm-none fs-5  ff1  fw300">
            {" "}
            Caring approach redefined for service and quality by <br />
            <b className="text-shadow-white"> NeedHands Nursing Agency</b>
          </span> */}
        <span className=" ff1 fs-3 text-shadow-grey  fw30">
          {" "}
          Caring approach redefined for service and quality by{" "}
          <br className="d-block d-sm-none" />
          <b className="text-shadow-whit"> NeedHands Nursing Agency</b>
        </span>
      </div>
      <div className="mt-auto p-2 text-white  text-center mb-2 phone">
        <button className=" btn  btn-outline-warning phone fw-bold rounded-0  px-3 py-2 py-md- mb-3 shadow shadow-4 ">
          <i className="h4 me-2   bi bi-telephone-fill  vibrate "></i>
          {address.phone}
        </button>
      </div>
    </div>
  );
};

export default Homebannertext;
