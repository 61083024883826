import React from "react";
import Nurse1 from "../../assets/nurses1.jpeg";
import MedSupport from "../../assets/home-section-2.png";
import { Parallax } from "react-parallax";
import { address } from "../../api/vars";
const Section2 = () => {
  const itemList = [
    {
      icon: "bi-calendar-plus",
      title: "24x7 Service",
      text: (
        <span>
          We are always a call away, just pick up your phone and call
          <a
            className="text-primary text-decoration-none text-bold"
            href={"tel:" + address.phone}
          >
            {" "}
            {address.phone}.{" "}
          </a>
          We provide best Medical care professionals on call or on demand.
        </span>
      ),
      link: "/#",
      linkText: "Find more",
    },
    {
      icon: "bi-person-check",
      title: "Trained Professional",
      text: (
        <span>
          All our Medical support staff are highly skilled, IPC trained,
          motivated and certified to give the best care that your residents
          deserve while going through the pandemic.
        </span>
      ),
      link: "/#",
      linkText: "Find more",
    },
  ];
  //   const random1 = Math.floor(Math.random() * ItemList.length);
  //   const random2 = random1 === 1 ? 0 : 1;
  //   return <div>this is new slide</div>;
  return (
    <div className=" user-select-none ">
      <Parallax
        className="border border-2 border-light p-0"
        bgImage={MedSupport}
        blur={{ min: -1, max: 1 }}
        strength={-100}
        renderLayer={(percentage) => (
          <div
            className="w-100"
            style={{
              position: "absolute",
              background: `rgba(0, 0, 0, .4`,
              // left: '50%',
              // top: '50%',
              width: "100%",
              height: "100%",
            }}
          />
        )}
      >
        <div className="vh-100   " style={{ width: "100vw" }}>
          <div className="df1 display-5 text-white mx-auto   text-center container mt-5">
            When your loved ones require care, <br />
            <span className="text-shadow-black text-warning">
              {" "}
              we are just a call away.
            </span>
          </div>
          <div
            className=" position-absolute  start-50 text-center translate-middle-x "
            style={{ width: "100%", bottom: "10%" }}
          >
            <div className="row row-cols-sm-2 g-4">
              {MakeCard(itemList[0])}

              {MakeCard(itemList[1])}
            </div>
          </div>
        </div>
        <div className="vh-1   " style={{ width: "100vw" }}></div>
      </Parallax>
    </div>
  );
};

function MakeCard(param) {
  return (
    <div className="car border-0 bg-inf ">
      <div className="card-body">
        <h5 className="card-title">
          <i
            className={`bi ${param.icon} display-3 text-white text-shadow-black`}
          ></i>
        </h5>
        <div className="card-title  mb-2  title text-white display-5 text-shadow-black">
          {param.title}
        </div>
        <p className="card-text text-white ff1 fs-4  mb-0 px-3 text-shadow-black">
          {param.text}
        </p>
        <a
          href="/#"
          className="btn btn-outline-warning fw-bold mt-3 shadow-3 rounded-0 "
        >
          {param.linkText}
        </a>
      </div>
    </div>
  );
}

export default Section2;
