export const NavItems = [
  { id: "", icon: "", text: "Home", link: "/", linktype: "no-hash" },
  { id: "", icon: "", text: "About us", link: "/", linktype: "no-hash" },
  {
    id: "",
    icon: "",
    text: "Services",
    link: "/#ourservices",
    linkType: "hash",
  },
  { id: "", icon: "", text: "Join us", link: "/jobs", linktype: "no-hash" },
  {
    id: "",
    icon: "",
    text: "Contact us",
    link: "/contactus",
    linktype: "no-hash",
  },
];
