import React from "react";
import { address } from "../../api/vars";
import Map from "./showmap";
const Section7 = () => {
  // const itemList = [
  //   {
  //     icon: "bi-calendar-plus",
  //     title: "24x7 Service",
  //     text: "We are always a call away, just pick your phone. We are offering best in class service .",
  //     link: "/#",
  //   },
  //   {
  //     icon: "bi-person-check",
  //     title: "Trained Professional",
  //     text: " We are always a call away, just pick your phone. We are offering best in class service .",
  //     link: "/#",
  //   },
  // ];

  return (
    <div>
      <div className="df1 display-5 text-warning text-shadow-black  col-12 col-md-9  pb-0 mb-3 pt-3 bg-inf ">
        Locate us
      </div>
      <div className="row ">
        <div className="col-12 col-lg-8 container pe-0 ">
          <Map noLink={true} />
        </div>
        <div className="col-12 col-lg-4  pb-5 ">
          <section className="">
            <div className="card border-0 bg-light">
              <div className="card-body">
                <div className="card-title display-5 title ">Working Hours</div>
                <p className="card-text col-12 col-lg-10 ff1 fs-3 fw300">
                  Let’s get together and discuss how NeedHands can address your
                  needs, your way. Just give us a call or a visit and we’ll make
                  all of it happen. You can also book via the following working
                  hours.
                </p>
              </div>
              <ul className="list-group list-group-flush ">
                <li className="list-group-item bg-light">
                  <div className="d-flex">
                    <div className="my-auto me-3">
                      <i className="bi bi-geo-alt-fill display-3 text-primary"></i>
                    </div>
                    <div className="col mt-aut ">
                      <div className="fs-4 ff1 small  mt-auto">
                        Visit us in person
                      </div>
                      <h5 className="fw-bold ff1 ">{address.address}</h5>
                    </div>
                  </div>
                </li>
                <li className="list-group-item bg-light ">
                  <div className="d-flex">
                    <div className="my-auto me-3">
                      <i className="bi bi-telephone-fill display-3 text-primary"></i>
                    </div>
                    <div className="col mt-auto ">
                      <div className="fs-5 ff1 small  mt-auto ">
                        Call Us Anytime
                      </div>
                      <h5 className="fw-bold ff1 mt-auto">{address.phone}</h5>
                    </div>
                  </div>
                </li>
                <li className="list-group-item bg-light">
                  <div className="d-flex">
                    <div className="my-auto me-3">
                      <i className="bi bi-envelope-fill display-3 text-primary"></i>
                    </div>
                    <div className="col mt-auto ">
                      <div className="fs-5 ff1 small  mt-auto">Email Us</div>
                      <h5 className="fw-bold ff1 mt-auto">
                        info@needhands.com.au
                      </h5>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export function MakeCard(param) {
  return (
    <div className="card border-0">
      <div className="card-body">
        <h5 className="card-title">
          <i className={`bi ${param.icon} fs-3 text-primary`}></i>
        </h5>
        <h5 className="card-title mb-2 fs-3 ">{param.title}</h5>
        <p className="card-text small">{param.text}</p>
        <a
          className="card-text link-primary"
          href={param.link}
          style={{ textDecoration: "none" }}
        >
          <small className="text-body-secondar">Find out more</small>
        </a>
      </div>
    </div>
  );
}

export default Section7;
